import React, { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

import { DocumentedPropsWithChildren, InnerRef } from 'bloko/common/helpers/types';

import { ButtonScale } from 'bloko/blocks/button';

import styles from 'bloko/blocks/button/button.less';

interface ButtonSelectProps {
    /** Размер контрола */
    scale?: ButtonScale;
    /** Флаг размера контрола, размер по контенту */
    flexible?: boolean;
    /** Флаг для отключения рамок у контрола */
    light?: boolean;
    /** Флаг disabled контрола */
    disabled?: boolean;
    /** Обработчик onClick */
    onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    /** Реф для кнопки */
    innerRef?: InnerRef;
}

const ButtonSelect: FC<DocumentedPropsWithChildren<ButtonSelectProps> & PropsWithChildren> = ({
    flexible,
    light,
    scale,
    innerRef,
    ...otherProps
}) => (
    <button
        className={classnames(styles['bloko-button-select'], {
            [styles['bloko-button-select_flexible']]: flexible,
            [styles['bloko-button-select_light']]: light,
            [styles[`bloko-button-select_${scale as ButtonScale}`]]: scale,
        })}
        type="button"
        ref={innerRef}
        {...otherProps}
    />
);

export default ButtonSelect;
