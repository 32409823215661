import classnames from 'classnames';

import { ComponentWithCustomElement, InnerRef } from 'bloko/common/helpers/types';

import styles from 'bloko/blocks/form/form.less';

interface FormItemProps {
    /** Флаг для включения обертки элементов формы: radio, checkbox  */
    baseline?: boolean;
    /** Флаг для включения обертки элементов формы: tags, link-switch. */
    text?: boolean;
    /** Кастомный компонент или ('div', 'span', 'section') */
    Element?: 'div' | 'span' | 'section';
    /** Реф для элемента */
    innerRef?: InnerRef;
}

const FormItem: ComponentWithCustomElement<FormItemProps, 'div'> = ({
    Element = 'div',
    baseline = false,
    text = false,
    children,
    innerRef,
    ...formItemProps
}) => {
    return (
        <Element
            {...formItemProps}
            ref={innerRef}
            className={classnames({
                [styles['bloko-form-item-baseline']]: baseline,
                [styles['bloko-form-item']]: !baseline,
                [styles['bloko-form-item-text']]: text,
            })}
        >
            {children}
        </Element>
    );
};

export default FormItem;
