import { ComponentPropsWithoutRef, FC, PropsWithChildren } from 'react';

import { DocumentedPropsWithChildren, InnerRef } from 'bloko/common/helpers/types';

import styles from 'bloko/blocks/icon/icon.less';

const IconVerticalAlignment: FC<
    DocumentedPropsWithChildren<ComponentPropsWithoutRef<'span'>> &
        PropsWithChildren & {
            innerRef?: InnerRef;
        }
> = ({ children, innerRef, ...spanProps }) => (
    <span className={styles['bloko-icon-vertical-alignment']} ref={innerRef} {...spanProps}>
        {children}
    </span>
);

export default IconVerticalAlignment;
