import React, { createRef, MouseEventHandler, PureComponent, ReactElement, ReactNode, forwardRef } from 'react';
import classnames from 'classnames';

import dropMenuItemStyles from 'bloko/blocks/drop/Menu/item.less';
import styles from 'bloko/blocks/tabs/tabs.less';

import { ComponentWithCustomElement } from '../../common/helpers/types';

export type TabIdType = string | number;
export type ActiveTabType = HTMLElement | null;

export interface TabProps {
    /** Флаг активности таба */
    active?: boolean;
    /** Активный таб по-умолчанию. Если передан - весь компонент tabs считается неуправляемым */
    defaultActive?: boolean;
    /** Идентификатор таба */
    id: TabIdType;
    /** Заголовок таба */
    children?: ReactNode;
    /** доп. текст по которому будет осуществляться поиск по табам (пользователю searchText не отображается) */
    searchText?: string;
    /** Кастомный компонент заголовка таба */
    Element: 'button' | 'a' | 'span';
    /** обработчик клика */
    onClick?: MouseEventHandler;
}

/**
 * Заголовок таба. Отдельно без обертки [Tabs](#tabs) не используется
 */
const Tab: ComponentWithCustomElement<TabProps, 'button'> = forwardRef(
    ({ Element = 'button', children, active, defaultActive, id, searchText, onClick, ...props }, ref) => (
        // @ts-expect-error Расхождение в типе ref: HTMLElement не включает свойства HTMLButtonElement и тд
        <Element {...props} onClick={onClick} draggable={false} ref={ref}>
            {children}
        </Element>
    )
);

export default Tab;

/**
 * Обертка-логика для табов в дропдауне
 */
export interface TabDropdownProps {
    /** Флаг активности таба */
    active?: boolean;
    /** обработчик клика */
    onClick?: MouseEventHandler;
    /** children */
    children: ReactElement;
}

export class TabDropdown extends PureComponent<TabDropdownProps> {
    onTabClick: MouseEventHandler = (event) => {
        this.props.onClick?.(event);
    };

    render(): ReturnType<typeof React.cloneElement> {
        const { children, active } = this.props;

        return React.cloneElement(children, {
            className: classnames(
                dropMenuItemStyles['bloko-drop-menu-item'],
                dropMenuItemStyles['bloko-drop-menu-item_selectable'],
                {
                    [dropMenuItemStyles['bloko-drop-menu-item_selected']]: this.props.active,
                }
            ),
            onClick: this.onTabClick,
            active,
        });
    }
}

/**
 * Обертка-логика для стандартных табов
 */
interface TabItemProps {
    /** Флаг активности таба */
    active?: boolean;
    /** обработчик клика */
    onClick?: MouseEventHandler;
    /** Колбэк для получения ссылки на DOM элемент */
    setElement?: (element: ActiveTabType) => void;
    /** children */
    children: ReactElement;
}

export class TabItem extends PureComponent<TabItemProps> {
    private elementRef = createRef<HTMLElement | null>();
    private preventScrollOnFocus = false;

    componentDidMount(): void {
        this.setActiveTab();
    }

    setActiveTab = (): void => {
        if (this.props.active) {
            this.props.setElement?.(this.elementRef.current);
        }
    };

    onFocus = (): void => {
        if (!this.preventScrollOnFocus) {
            this.props.setElement?.(this.elementRef.current);
        }
        this.preventScrollOnFocus = false;
    };

    onMouseDown = (): void => {
        this.preventScrollOnFocus = true;
    };

    componentDidUpdate(prevProps: TabItemProps): void {
        if (this.props.active !== prevProps.active) {
            this.setActiveTab();
        }
    }

    onTabClick: MouseEventHandler = (event) => {
        this.props.onClick?.(event);
    };

    render(): ReturnType<typeof React.cloneElement> {
        const { children, active } = this.props;

        return React.cloneElement(children, {
            className: classnames(styles['bloko-tabs__item'], {
                [styles['bloko-tabs__item_active']]: active,
            }),
            onClick: this.onTabClick,
            onFocus: this.onFocus,
            onMouseDown: this.onMouseDown,
            tabIndex: 0,
            active,
            ref: this.elementRef,
        });
    }
}
