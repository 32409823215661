import React, {
    Children,
    FC,
    isValidElement,
    PropsWithChildren,
    ReactElement,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
} from 'react';

import Menu, { MenuPlacement } from 'bloko/blocks/drop/Menu';
import useMenuOptionsFiltered from 'bloko/blocks/drop/Menu/useMenuOptionsFiltered';
import { IconColor, IconLink, BarsScaleSmall } from 'bloko/blocks/icon';
import Text, { TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { KeyCode } from 'bloko/common/constants/keyboard';

import { TabDropdown, TabDropdownProps } from 'bloko/blocks/tabs/Tab';
import TabsSearchField from 'bloko/blocks/tabs/TabsSearchField';

import styles from 'bloko/blocks/tabs/tabs.less';

interface TabsDropdownControllerArgs {
    /** Видимость дропдауна */
    dropdownVisible?: boolean;
    /** Колбэк для установки видимости дропдауна */
    setDropdownVisible: (isVisible: boolean) => void;
    /** Табы */
    children: ReactNode;
    /** Параметры [Menu](#menu) */
    menuParams?: Partial<Parameters<typeof Menu>[0]>;
    /** Поиск по табам в дроп-меню */
    hasSearchSupport?: boolean;
    /** Плейсхолдер поиска */
    searchPlaceholder?: string;
    /** Сообщение когда поиск ничего не нашёл */
    emptySearchResultMessage?: string;
}

const validateChild = (child: ReactNode): child is ReactElement<TabDropdownProps> => {
    return isValidElement(child) && child.type === TabDropdown;
};

const enrichChildren = (children: ReactNode, setFilterQuery: (query: string) => void) => {
    return Children.map(children, (tabDropdown) => {
        if (!validateChild(tabDropdown)) {
            return undefined;
        }
        return React.cloneElement<TabDropdownProps>(tabDropdown, {
            onClick: (event) => {
                setFilterQuery('');
                tabDropdown.props.onClick?.(event);
            },
        });
    });
};

const TabsDropdownController: FC<TabsDropdownControllerArgs & PropsWithChildren> = ({
    dropdownVisible,
    setDropdownVisible,
    children,
    menuParams,
    hasSearchSupport,
    searchPlaceholder,
    emptySearchResultMessage,
}) => {
    const { filterQuery, setFilterQuery, filteredOptions } = useMenuOptionsFiltered<ReactNode, ReactNode>(
        children,
        validateChild
    );
    const options = hasSearchSupport ? enrichChildren(filteredOptions, setFilterQuery) : filteredOptions;
    const notFound = hasSearchSupport && Children.count(options) === 0 && Children.count(children) > 0;
    const activatorRef = useRef(null);

    const keyboardControlHandler = useCallback(
        (event: KeyboardEvent) => {
            if (dropdownVisible && event.keyCode === KeyCode.ESC) {
                setDropdownVisible(false);
            }
        },
        [setDropdownVisible, dropdownVisible]
    );

    useEffect(() => {
        document.addEventListener('keydown', keyboardControlHandler);
        return () => {
            document.removeEventListener('keydown', keyboardControlHandler);
        };
    }, [keyboardControlHandler]);

    return (
        <Menu
            placement={MenuPlacement.BottomEnd}
            {...menuParams}
            activatorRef={activatorRef}
            show={dropdownVisible}
            onClose={() => setDropdownVisible(false)}
            render={() => (
                <>
                    {hasSearchSupport && (
                        <TabsSearchField
                            filterQuery={filterQuery}
                            setFilterQuery={setFilterQuery}
                            placeholder={searchPlaceholder}
                        />
                    )}
                    <div className={styles['bloko-tabs__dropdown-items-container']}>
                        {notFound && emptySearchResultMessage ? (
                            <div className={styles['bloko-tabs__dropdown-items-empty']}>
                                <VSpacing base={6} />
                                <Text importance={TextImportance.Tertiary}>{emptySearchResultMessage}</Text>
                                <VSpacing base={6} />
                            </div>
                        ) : (
                            options
                        )}
                    </div>
                </>
            )}
        >
            <div className={styles['bloko-tabs__icon-container']} ref={activatorRef}>
                <IconLink onClick={() => setDropdownVisible(!dropdownVisible)}>
                    <BarsScaleSmall initial={IconColor.Gray50} />
                </IconLink>
            </div>
        </Menu>
    );
};

export default TabsDropdownController;
