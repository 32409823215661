import { PropsWithChildren } from 'react';
import classnames from 'classnames';

import { ComponentWithCustomElement, InnerRef } from 'bloko/common/helpers/types';

import styles from 'bloko/blocks/text/text.less';

export enum TextSize {
    Small = 'small',
    Medium = 'medium',
    Large = 'large',
    ExtraLarge = 'extra-large',
}

export enum TextImportance {
    Primary = 'primary',
    Secondary = 'secondary',
    Tertiary = 'tertiary',
}

export interface TextProps {
    /** Тип элемента */
    Element?: 'span' | 'p' | 'div' | 'b' | 'strong';
    /** Размер текста */
    size?: TextSize;
    /** Флаг выделения */
    strong?: boolean;
    /** Флаг курсива */
    italic?: boolean;
    /** "Важность" текста */
    importance?: TextImportance;
    /** Реф для элемента */
    innerRef?: InnerRef;
}

const Text: ComponentWithCustomElement<PropsWithChildren<TextProps>, 'div'> = ({
    children,
    Element = 'div',
    size = TextSize.Medium,
    strong = false,
    italic = false,
    importance = TextImportance.Primary,
    innerRef,
    ...otherProps
}) => {
    return (
        <Element
            {...otherProps}
            className={classnames(styles['bloko-text'], {
                [styles['bloko-text_small']]: size === TextSize.Small,
                [styles['bloko-text_large']]: size === TextSize.Large,
                [styles['bloko-text_extra-large']]: size === TextSize.ExtraLarge,
                [styles['bloko-text_strong']]: strong,
                [styles['bloko-text_italic']]: italic,
                [styles['bloko-text_secondary']]: importance === TextImportance.Secondary,
                [styles['bloko-text_tertiary']]: importance === TextImportance.Tertiary,
            })}
            ref={innerRef}
        >
            {children}
        </Element>
    );
};

export default Text;
