import { FC, PureComponent, Ref, MutableRefObject, useCallback, PropsWithChildren } from 'react';

import { NotificationKind } from 'bloko/blocks/notificationManager/constants';
import NotificationContext, {
    CommonNotificationProps,
    NotificationContextValue,
} from 'bloko/blocks/notificationManager/context';

import styles from 'bloko/blocks/notificationManager/notification-manager.less';

export const NOTIFICATION_AUTO_CLOSE_TIME_MS = 5000;
export const NOTIFICATION_ANIMATION_TIMEOUT_MS = 300;
export const NOTIFICATION_MANAGER_CLASS_JS = 'Bloko-Notification-Manager';
export const NOTIFICATION_MANAGER_CLASS_CSS = styles['bloko-notification-manager'];

interface NotificationDeclarationProps extends CommonNotificationProps {
    /** Объект контекста notificationManager */
    context: NotificationContextValue;
    /** Обработчик закрытия нотификации */
    onClose?: () => void;
    /*
     * Функция принимающая в качестве аргумента сссылку на функцию закрытия ноотификации, используется для
     * передачи ссылки на функцию закрытия в вызывабщий код
     */
    closeFuncRef?: (closeFunc: VoidFunction | null) => void;
}

class NotificationDeclaration extends PureComponent<NotificationDeclarationProps> {
    id: number | null = null;

    componentDidMount() {
        const { context, closeFuncRef, ...props } = this.props;
        if (!this.id) {
            this.id = context.addNotification(props);
        }
        closeFuncRef?.(() => this.id !== null && context.closeNotification(this.id));
    }

    componentDidUpdate(prevProps: NotificationDeclarationProps) {
        const wasInNotifications = prevProps.context.notifications.some((notification) => notification.id === this.id);
        const isInNotifications = this.props.context.notifications.some((notification) => notification.id === this.id);
        const removed = wasInNotifications && !isInNotifications;

        if (removed) {
            this.props.onClose?.();
        }
        if (this.props.children !== prevProps.children && this.id !== null) {
            this.props.context.updateProps(this.id, { children: this.props.children });
        }
    }

    componentWillUnmount() {
        this.props.closeFuncRef?.(null);
        if (this.id) {
            this.props.context.removeNotification(this.id);
            this.id = null;
        }
    }

    render() {
        return null;
    }
}

interface NotificationProps extends CommonNotificationProps {
    /** Обработчик закрытия нотификации */
    onClose?: () => void;
    /** ref для передачи ссылки на функцию закрытия нотификации вовне */
    closeFuncRef?: Ref<VoidFunction | null>;
}

const Notification: FC<NotificationProps & PropsWithChildren> = ({
    autoCloseDelay = NOTIFICATION_AUTO_CLOSE_TIME_MS,
    onClose,
    closeFuncRef,
    ...props
}) => {
    const updateCloseFuncRef = useCallback(
        (closeFunc: VoidFunction | null) => {
            if (!closeFuncRef) {
                return;
            }
            if (typeof closeFuncRef === 'function') {
                closeFuncRef(closeFunc);
                return;
            }

            (closeFuncRef as MutableRefObject<VoidFunction | null>).current = closeFunc;
        },
        [closeFuncRef]
    );
    return (
        <NotificationContext.Consumer>
            {(value) => (
                <NotificationDeclaration
                    autoCloseDelay={autoCloseDelay}
                    closeFuncRef={updateCloseFuncRef}
                    onClose={onClose}
                    {...props}
                    context={value}
                />
            )}
        </NotificationContext.Consumer>
    );
};

export default Notification;
export { NotificationKind };
